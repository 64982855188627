<template>
    <div>
        <vx-card>
            <div slot="no-body" class="tabs-container px-6 pt-6">
                <div class="vx-row">
                    <h3 class="ml-2">Chi tiết chấm công</h3>
                </div>
                <!-- Content Row -->
                <div class="vx-row">
                    <div class="vx-col md:w-1/4 w-full mt-2">
                        <vs-input class="w-full" label="Mã nhân viên" :disabled="true"
                                  v-model="employeeInfo.employeeCode"
                                  v-validate="'required'" name="employeeCode"/>
                        <span class="text-danger text-sm">{{ errors.first('employeeCode') }}</span>
                    </div>
                    <div class="vx-col md:w-1/4 w-full mt-2">
                        <vs-input class="w-full" label="Họ tên" :disabled="true"
                                  v-model="employeeInfo.fullName"
                                  v-validate="'required'" name="fullName"/>
                        <span class="text-danger text-sm">{{ errors.first('fullName') }}</span>
                    </div>
                </div>
                <div class="vx-row">
                    <div class="vx-col md:w-1/4 w-full mt-2">
                        <span>Đi muộn: <a
                            style="color: red; font-size: 25px;margin-left: 10px; margin-right: 5px;">{{ lamMuon.length }}</a>ca.</span>
                    </div>
                    <div class="vx-col md:w-1/4 w-full mt-2">
            <span>Về sớm: <a
                style="color: red; font-size: 25px; margin-left: 10px; margin-right: 5px;">{{ veSom.length }}</a>ca.</span>
                    </div>
                    <div class="vx-col md:w-1/4 w-full mt-2">
                        <span>Nghỉ làm: <a
                            style="color: red; font-size: 25px; margin-left: 10px; margin-right: 5px;">{{ nghiLam.length }}</a>ca.</span>
                    </div>
                </div>

                <div style="height: 30px"></div>

                <div class="vx-row no-scroll-content">
                    <calendar-view
                        ref="calendar"
                        :displayPeriodUom="calendarView"
                        :show-date="showDate"
                        :events="simpleCalendarEvents"
                        :eventTop="windowWidth <= 400 ? '2rem' : '3rem'"
                        eventBorderHeight="0px"
                        eventContentHeight="1.65rem"
                        class="theme-default">

                        <div slot="header" class="mb-4">

                            <div class="vx-row no-gutter">
                                <!-- Month Name -->
                                <div class="vx-col w-1/3 items-center sm:flex hidden">
                                    <!-- Add new event button -->
                                </div>
                                <div class="vx-col w-1/3 items-center sm:flex hidden">
                                    <!-- Add new event button -->
                                </div>

                                <!-- Current Month -->
                                <div
                                    class="vx-col sm:w-1/3 w-full sm:my-0 my-3 flex sm:justify-end justify-center order-last">
                                    <div class="flex items-center">
                                        <feather-icon
                                            :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
                                            @click="updateMonth(-1)"
                                            svgClasses="w-5 h-5 m-1"
                                            class="cursor-pointer bg-primary text-white rounded-full"/>

                                        <span class="mx-3 text-xl font-medium whitespace-no-wrap">{{
                                                showDateString
                                            }}</span>

                                        <feather-icon
                                            :icon="$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
                                            @click="updateMonth(1)"
                                            svgClasses="w-5 h-5 m-1"
                                            class="cursor-pointer bg-primary text-white rounded-full"/>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </calendar-view>
                </div>

                <!-- Save & Reset Button -->
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <div class="mt-8 flex flex-wrap items-center justify-end">
                            <vs-button class="ml-4 mt-2 mb-5" type="border" color="warning" @click="employeeList">Quay
                                lại
                            </vs-button>
                        </div>
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script>

import vSelect from 'vue-select';
import Datepicker from 'vuejs-datepicker';
import moduleCalendar from '@/store/calendar/moduleCalendar.js';
import {CalendarView, CalendarViewHeader} from "vue-simple-calendar"
import {vi} from "vuejs-datepicker/src/locale";
import moment from 'moment';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

require("vue-simple-calendar/static/css/default.css");


export default {
    components: {
        vSelect,
        Datepicker,
        CalendarView,
        CalendarViewHeader,
        flatPickr
    },
    data() {
        return {
            lamMuon: [],
            veSom: [],
            nghiLam: [],

            employeeInfo: {},
            events: [],

            showDate: new Date(),
            showDateString: moment(this.showDate).format('DD/MM/YYYY'),
            advanceSetting: false,

            langVi: vi,
            calendarView: 'month',

        }
    },
    created() {
        let employeeId = this.$route.query.id;

        if (employeeId) {
            this.$crm.post('/employee/find-by-id/' + employeeId).then((response) => {
                this.$vs.loading.close();
                this.employeeInfo = response.data;
                this.filter();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        }
        //load time
        this.$store.registerModule('calendar', moduleCalendar);
        this.$store.dispatch("calendar/fetchEvents");
        this.$store.dispatch("calendar/fetchEventLabels");

    },
    beforeDestroy() {
        this.$store.unregisterModule('calendar')
    },
    computed: {
        simpleCalendarEvents() {
            return this.events;
        },
        windowWidth() {
            return this.$store.state.windowWidth
        }
    },

    methods: {

        employeeList() {
            this.$router.push(`/user/time-keeping`).catch(() => {
            })
        },
        showAdvanceSetting(checked) {
            this.advanceSetting = checked;
        },
        updateMonth(val) {
            this.showDate = this.$refs.calendar.getIncrementedPeriod(val);
            this.showDateString = moment(this.showDate).format('DD/MM/YYYY');
            let start = new Date(this.showDate.getFullYear(), this.showDate.getMonth(), 1);
            let end = new Date(new Date(this.showDate.getFullYear(), this.showDate.getMonth(), 1).setDate(new Date(this.showDate.getFullYear(), this.showDate.getMonth(), 1).getDate() + 31));

            let searchInfo = {
                organizationBranchId: this.employeeInfo.organizationBranchId,
                employeeId: [this.$route.query.id],
                start: start.toISOString(),
                end: end.toISOString()
            };
            this.getData(searchInfo, this);
        },

        filter() {
            let date = new Date();
            let start = new Date(date.getFullYear(), date.getMonth(), 1);
            let end = new Date(new Date(date.getFullYear(), date.getMonth(), 1).setDate(new Date(date.getFullYear(), date.getMonth(), 1).getDate() + 31));
            let searchInfo = {
                organizationBranchId: this.employeeInfo.organizationBranchId,
                employeeId: [this.$route.query.id],
                start: start.toISOString(),
                end: end.toISOString()
            };
            this.getData(searchInfo, this);
        },

        getData(searchInfo, that) {
            this.$vs.loading();
            that.$crm.post('/time-keeping/find', searchInfo).then((response) => {
                that.lamMuon = response.data.filter(v => (v.startHour + v.bufferTime) < v.checkinToTime);
                that.veSom = response.data.filter(v => v.checkinToTime > 0 && v.endHour > v.checkoutToTime);
                that.nghiLam = response.data.filter(v => !v.checkinToTime);
                that.events = [];
                response.data.forEach(v => {
                    that.events.push({
                        id: v.id,
                        title: v.checkinTime ? "Checkin: " + v.checkinTime : "Checkin: ",
                        startDate: moment(v.date, "DD/MM/YYYY"),
                        endDate: moment(v.date, "DD/MM/YYYY")
                    });
                    that.events.push({
                        id: v.id + 100000,
                        title: v.checkoutTime ? "Checkout: " + v.checkoutTime : "Checkout: ",
                        startDate: moment(v.date, "DD/MM/YYYY"),
                        endDate: moment(v.date, "DD/MM/YYYY")
                    });
                });
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
    }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/simple-calendar.scss";

.vs-dialog {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100% - 20px);
}

.vs-dialog::-webkit-scrollbar {
    width: 0px;
}
</style>
